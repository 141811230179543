import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import 'bootstrap';

class Payroll extends React.Component {
  render() {
    const { data, location } = this.props;

    return (
      <Layout data={data} location={location}>
        <h1>{data.markdownRemark.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
      </Layout>
    );
  }
}

export default Payroll;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        template
        title
        type
        red_track
        description
      }
    }
  }
`;